<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Data Antrean BPJS</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <b-row>
                        <b-col lg="8">
                        <b-form-row>
                            <b-col lg="5">
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="filter.tanggal">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                            </b-col>
                        </b-form-row>
                        </b-col>
                        <b-col lg="4">
                            <button @click="doFill" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1 float-right"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800 float-right mr-2" @click="doReset">Reset</button>
                        </b-col>
                    </b-row>

                    <div class="row">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nomor registrasi</th>
                                    <th>Nama Pasien</th>
                                    <th>Tanggal Antrian</th>
                                    <th>Data Antrian</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in data.data" :key="v.id">
                                        <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                        <td>{{v.laar_code||"-"}}</td>
                                        <td>{{v.ap_fullname||"-"}}</td>
                                        <td>{{v.laar_created_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                                        <td>                
                                            <a href="javascript:;" class="list-icons-item"
                                            @click="openDetail(v)"
                                            data-toggle="tooltip" data-placement="top" title="Lihat Data Antrean"><i
                                                class="icon-eye"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="data.data.length == 0">
                                    <tr>
                                        <td colspan="5">
                                        <div class="text-center">
                                            <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                            <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </b-card>

                
            <b-modal v-model="openModalAntrian" :title="'Detail Antrian'" size="md" ok-only>
                <div class="row" id="billing">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Task ID</th>
                                <th>Task Name</th>
                                <th>Keterangan</th>
                                <th>Tanggal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(v,k) in (rowDetail.dataLogAntrean||[])" :key="k">
                                <td>{{k+1}}</td>
                                <td>{{v.laaa_task_id}}</td>
                                <td>{{toTask(v.laaa_task_id)}}</td>
                                <td>{{v.laaa_keterangan||"-"}}</td>
                                <td>
                                    <span v-if="v.laaa_created_date">
                                        {{v.laaa_created_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                                    </span>
                                    <span v-else> - </span>
                                </td>
                            </tr>
                            <tr v-if="!(rowDetail.dataLogAntrean||[]).length">
                                <td colspan="99">Tidak ada Data</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-modal>
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'


export default {
  extends: GlobalVue,
  components: {
    Datepicker
  },
  data() {
    return {
        dateAntrean : null,
        rowDetail: {},
        openModalAntrian: false
    }
  },
  methods: {
    doFill(){
       this.doFilter()
    },
    openDetail(data){
       this.rowDetail = data
       this.openModalAntrian = true
    },
    toTask(taskid){
        if(taskid == 1){
            return "mulai waktu tunggu admisi"
        }else if(taskid == 2){
            return "akhir waktu tunggu admisi/mulai waktu layan admisi"
        }else if(taskid == 3){
            return "akhir waktu layan admisi/mulai waktu tunggu poli"
        }else if(taskid == 4){
            return "akhir waktu tunggu poli/mulai waktu layan poli"
        }else if(taskid == 5){
            return "akhir waktu layan poli/mulai waktu tunggu farmasi"
        }else if(taskid == 6){
            return "akhir waktu tunggu farmasi/mulai waktu layan farmasi membuat obat"
        }else if(taskid == 7){
            return "akhir waktu obat selesai dibuat"
        }else if(taskid == 99){
            return "tidak hadir/batal"
        }else{
            return "-"
        }
    }
  },
  mounted() {
      this.apiGet()
  },
}

</script>
